import { Link } from 'react-router-dom'
import { styled } from '../styles/stitches.config'
import { Box } from './elements/Box'
import { CloseIcon, MenuIcon } from './icons'
import { Flex } from './elements/Flex'
import { useCallback, useState } from 'react'
import { IconButton } from './button'
import { routes } from 'configs/constants'
import useAuth from 'hooks/use-auth'
import HeaderUserbox from './sidebarLayout/Header/Userbox'

export const Header = () => {
  const [isOpen, setIsOpen] = useState(false)

  const { user, isLoggingIn } = useAuth()

  const closeSidebar = useCallback(() => {
    setIsOpen(false)
  }, [])
  return (
    <StyledHeader>
      <Flex align={'center'}>
        <IconButton className="menu-open" onClick={() => setIsOpen(true)} aria-label="Open main menu" css={{ mr: '$4' }}>
          <MenuIcon fontSize={24} aria-hidden="true" />
          <span className="sr-only">Open main menu</span>
        </IconButton>
        <Flex as={Link} to="/">
          <Box as="img" css={{ 'width': 160, '@sm': { width: 190 } }} src="/images/dlt-alert-logo.png" alt="logo" />
        </Flex>
      </Flex>

      <StyledNav aria-label="Main menu" aria-expanded={isOpen}>
        <Flex className="menu-close" align="center" css={{ gap: '$3', p: '$4' }}>
          <IconButton onClick={closeSidebar}>
            <CloseIcon fontSize={24} aria-hidden="true" />
            <span className="sr-only">Close main menu</span>
          </IconButton>
          <Box as={Link} to="/" css={{ display: 'inline-block' }}>
            <Box as="img" css={{ width: 187 }} src="/images/dlt-alert-logo.png" alt="logo" />
          </Box>
        </Flex>

        <StyledUl>
          {user && isLoggingIn ? (
            <>
              <Box as="li" className="self-center">
                <HeaderUserbox />
              </Box>
            </>
          ) : (
            <Box as="li" className="self-center" css={{ 'p': '0', '@sm': { p: '0 4rem' } }}>
              <StyledNavLink css={{ 'm': '1rem 0 0', 'color': '$primary', '@sm': { m: 0, maxWidth: '150' } }} to={routes.login}>
                Login
              </StyledNavLink>
            </Box>
          )}
        </StyledUl>
      </StyledNav>

      <StyledBackdrop hidden={!isOpen} onClick={closeSidebar} />
    </StyledHeader>
  )
}

const StyledHeader = styled('header', {
  'position': 'fixed',
  'width': '100%',
  'top': 0,
  'left': 0,
  'zIndex': 1,
  'padding': '1rem 1.15rem',
  'mb': 3,
  'bgImage': '$headerGradient',
  'backdropFilter': 'blur(20px)',
  'borderRadius': '0px 0px 15px 15px',
  '@sm': {
    'position': 'static',
    'width': 'auto',
    'display': 'flex',
    'alignItems': 'center',
    'left': 0,
    // padding is in conjunction with userbox styles
    'padding': '1rem 1.15rem',
    'borderRadius': '0px 0px 15px 15px',
    'backdropFilter': 'blur(20px)',
    'linearGradient': '$linearGradientGray',
    '& .menu-open': {
      display: 'none',
    },
    // adjusting userbox, so that we can get right dropdown position
    '&  .user-box-btn': {
      paddingRight: 'calc(15px + 32px)',
      paddingBlock: '22px',
    },
    // doing like this because of userbox
    '& .login-btn': {
      marginRight: 32,
    },
  },
  '@md': {
    mx: 54,
  },
})

const StyledNav = styled('nav', {
  'position': 'absolute',
  'left': -294,
  'top': 0,
  'zIndex': '99',
  'height': '100vh',
  'overflowY': 'auto',
  'transition': 'left 0.3s ease, box-shadow 0.3s ease',
  'bg': '$offcanvasGradient',
  'backdropFilter': 'blur(20px)',
  'borderRadius': '0px 15px 15px 0px',

  '&[aria-expanded="true"]': {
    left: 0,
    outline: 'none',
    boxShadow: '3px 0 12px $black40',
  },

  '@sm': {
    'position': 'static',
    'height': 'auto',
    'transition': 'none',
    'bg': 'none',
    'borderRadius': 0,
    'backdropFilter': 'none',
    'ml': 'auto',
    'alignSelf': 'stretch',

    '& .menu-close': {
      display: 'none',
    },
  },
})

const StyledUl = styled('ul', {
  'width': 294,
  'my': 0,
  'ml': 'auto',
  'padding': 0,
  'gap': '35px',
  'listStyle': 'none',

  '@sm': {
    'display': 'flex',
    'width': 'auto',
    'height': '100%',
    '& .self-center': {
      alignSelf: 'center',
    },
  },
})

// can't directly use NavLink due to an issue with stitches
const StyledNavLink = styled(Link, {
  'display': 'block',
  'borderTop': '1px solid $white66',
  'borderBottom': '1px solid $white66',
  'linearGradient': '$linearGradientGray',
  'mb': '$3',
  'pl': '$4',
  'py': 10,
  'color': '$white',
  'fontSize': '$base',
  'textTransform': 'uppercase',
  'textDecoration': 'none',
  '@sm': {
    'dflex': 'center',
    'height': '100%',
    'bg': 'none',
    'border': 'none',
    'fontWeight': '$normal',
    'py': 0,
    'px': 10,
    'mb': 0,
    'alignSelf': 'stretch',
    '&:hover': {
      borderBottom: '2px solid $primaryBorder',
    },
    '&.active': {
      fontWeight: '$bold',
      borderBottom: '2px solid $primaryBorder',
    },
  },
})

const StyledBackdrop = styled('div', {
  position: 'fixed',
  inset: '0',
  height: '100vh',
  zIndex: 98,
  background: '$black80',
  cursor: 'default',
})

export default Header
