import { RiskScoreEmailToUserState } from 'features/incidents/components/type'

export enum ApiKey {
  insurance = 'insurance',
  policies = 'policies',
  plans = 'plans',
  users = 'users',
  requests = 'requests',
  company = 'company',
  admins = 'admins',
  message = 'message',
  comment = 'comments',
  Internal_message = 'message',
  Internal_comment = 'comments',
  profile = 'profile',
}
export enum Comment {
  message = 'message',
  userThreatId = 'userThreatId',
  type = 'type',
  SHOW = 'Show',
  HIDE = 'Hide',
}
export enum Comment_Type {
  type = 'External',
  type_Internal = 'Internal',
}

export const AlertKeyEnum = {
  CONFIDENCE_LEVEL: 'Confidence Level',
  CLASSIFICATION: 'Classification',
  PLAN_STATUS: 'Plan Status',
  STATUS: 'status',
  FA: '2FA',
  EDR: 'EDR',
  BACKUPS: 'Backups',
  PERSONAL_TRAINING: 'Personal Training',
  PATCH_MANAGEMENT: 'Patch Management',
  PREMIUM: 'Premium',
  DEFAULT: 'Failed to register, Please try again.',
}

export enum IncidentEnum {
  SENTINEL_VALIDATION = 'Sentinel key is required',
  SENTINEL_SUCCESS_MESSAGE = 'Sentinel key updated successfully',
  SENTINEL_ERROR_MESSAGE = 'Failed to update sentinel key',
  DESCRIPTION_AMOUNT = 'Aggregate Coverage Amount',
  DESCRIPTION_DATE = 'Validation Date',
  INSURANCE_DETAIL = 'insuranceDetail',
  PENDING = 'Pending',
  VALID = 'Valid',
  INVALID = 'Invalid',
  RISK_SCORE = 'Risk Score',
  RE_EVALUATION = 'Re-evaluation',
  CASE_CLOSED = 'CASE CLOSED',
  ACTION_COMPLETED_SUCCESSFULLY = 'ACTION COMPLETED SUCCESSFULLY',
  DOC_LABEL = 'Upload an Document',
  DOC_UPLOAD_LABEL = 'Upload an official file/doc supporting your action',
  USER_DOC_UPLOAD_LABEL = 'User Re-evalution file/doc supporting the claim amount',
  MISP_DATA = 'mispData',
}

export enum ValidationErrors {
  NAME = 'Name is required',
  Message = 'Message is required',
  FIRST_NAME = 'First name is required',
  LAST_NAME = 'Last name is required',
  COMPANY_NAME = 'Company name is required',
  COMPANY_NAMES = 'Company name must have alphabet shorter than or equal to 30 characters and must end on word and number',
  COMPANY_NAME_VALIDATION = 'Name at least have 2, but not more than 20 charaters',
  FIRST_NAME_VALIDATION = 'First name at least have 2, but not more than 10 charaters',
  LAST_NAME_VALIDATION = 'Last name at least have 2, but not more than 10 charaters',
  PLAN_NAME = 'Policy name is required',
  POLICY_NUMBER = 'Policy Number is required',
  TITLE = 'Title is required',
  EMAIL_VALIDATION = 'Email must be a valid email address',
  EMAIL = 'Email is required',
  WEBSITE_URL = 'Website url is require',
  WEBSITE_URL_VALIDATION = 'Please provide valid website url',
  INSURANCE_COMPANY = 'Insurance Company is required',
  PORTFOLIO_NUMBER = 'Portfolio number is required',
  DESCRIPTION = 'Description is required',
  ISSUE_NAME = 'Issue name is required',
  COMPANY = 'Company name is required',
  COMPANY_URL = 'Company url is required',
  ADDRESS = 'Address is required',
  ADDRESS_VALIDATION = 'Address at least have 2, but not more than 30 charaters',
  OLD_PASSWORD = 'Old password is required',
  NEW_PASSWORD = 'New password is required',
  MATCH_PASSWORD = 'Passwords must match',
  CONFIRM_PASSWORD = 'Confirm password is required',
  PLAN_DURATION = 'Policy duration is required',
  PRIMAR_SERVICE = 'Primary service is required',
  MAX_COVERAGE = 'Max coverage is required',
  DEFAULT = 'Failed to register, Please try again.',
  DESCRIPTION_VALIDATION = 'Description must contain 10 charaters',
  PREMIUM = 'Premium amount is required',
  PREMIUM_VALIDATION = 'Premium amount should be greater than zero',
  INSURANCE_PLAN = ' Policy is required',
  PAYMENT_STATUS = 'Payment Method is required',
  PHONE = 'Phone number is required',
  PHONE_VALIDATION = 'Phone number start with + followed by the country code with 10 digits',
  ADMIN_ROLE = 'Admin role is required',
  WHITE_SPACE = 'White space not allowed',
  NO_WHITE_SPACE = 'No white space is allowed',
  NAMED = 'Username must be between 2 and 70 characters and contain a single space between words.',
  SERVICE_ERROR = 'Primary service (Sentinel One) must be selected in order to assign a policy. ',
  DISCOUNT = 'Discount is required',
  POLICY_NAME = 'Policy Name should not be greater than 100 characters',
  DISCOUNT_ERROR = 'Discount should not be less then 0 and greater than 20 ',
  MAX_COVERAGE_ERROR = 'Max coverage should be in between 5000 to 25000',
  DUPLICATE_FILES = 'Duplicate file  found. Please resolve before submitting.',
  REQUIRED = 'This field is required',
  DESCRIPTION_MIN_LENGTH = 'Description must contain 10 charaters',
  DESCRIPTION_MAX_LENGTH = 'Description should  not more than 300 characters ',
  ANNUAL_REVENUE_TYPEERROR = 'Annual Revenue must be a number',
  ANNUAL_REVENUE_POSITIVE = 'Annual Revenue must be greater than zero',
  BASE_PREMIUM_ERROR = 'Base Premium should be greater then 0',
  SUB_ADMIN_POLICY_ERROR = 'At least one policy must be selected',
  SUB_ADMIN_COMPANY_NAME_VALIDATION = 'Company name should not contain spaces. Use hyphens (-) instead.',
}

export enum NotificationMessages {
  POLICY_SUCCESS = 'Policy feature added successfully',
  POLICY_UPDATE_SUCCESS = 'Policy updated successfully',
  PROFILE_UPDATE_SUCCESS = 'Profile updated successfully',
  COMPANY_UPDATE_SUCCESS = 'Company details updated successfully',
  PASSWORD_UPDATE_SUCCESS = 'Password updated successfully',
  POLICY_DELETE_SUCCESS = 'Policy deleted successfully',
  USER_SUCCESS = 'User added successfully',
  INSURANCE_ACCEPT_SUCCESS = 'Request accepted successfully',
  INSURANCE_REJECT_SUCCESS = 'Request rejected successfully',
  ADMIN_SUCCESS = 'Admin added successfully',
  PASSWORD_SET_SUCCESS = 'Password set successfully',
  PLAN_SUCCESS = 'Policy added successfully',
  ROLE_SUCCESS = 'Admin role updated successfully',
  SELF_BLOCK = 'You cannot perform any action on yourself',
  RESEND_LINK = 'Verification email sent successfully',
  COMMENT_DELETE = 'Comment deleted successfully',
  COMMENT_EDIT = 'Comment edited successfully',
  PREMIUM_EDIT = 'Premium edited successfully',
  POLICY_Active_SUCCESS = 'Insurance policy Activated successfully',
  POLICY_INACTIVE_SUCCESS = 'Insurance policy InActivated successfully',
  ACCEPT_CLAIM = 'Claim has been accepted successfully',
  REJECT_CLAIM = 'Claim has been Rejected successfully',
  RENEW_POLICY = 'Policy has been renewed successfully',
  RESEND_EMAIL = 'Verification email resent successfully',
  RESEND_PAYMENT_REMINDER = 'Payment reminder email resent successfully',
  DELETE_USER = 'User deleted successfully',
  ADMIN_DELETE = 'Sub admin deleted successfully',
  EDIT_POLICY_FEATURE = 'Policy feature updated successfully',
  Risk_Score_Email = 'Risk score email sent successfully',
}

export enum UserStatus {
  ALL = 'All',
  PENDING = 'Pending',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  BLOCKED = 'Blocked',
  DELETED = 'Deleted',
}

export enum ClaimStatus {
  ALL = 'All',
  APPLICABLE = 'Applicable',
  NOT_APPLICABLE = 'Not Applicable',
  CLAIM_RAISED = 'Claim Raised',
  APPROVED = 'Approved',
  DISAPPROVED = 'Disapproved',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  CANCELLED = 'Cancelled',
  RE_EVALUATE = 'Re-Evaluate',
  ACTIVE = 'Active',
  SERVICE_ACTIVATION_PENDING = 'Service Activation Pending',
}

export enum IncidentStatus {
  ALL = 'All',
  INCIDENT_DETECTED = 'Incident Detected',
  INCIDENT_VERIFIED = 'Incident Verified',
  CLAIM_INITIATED = 'Claim Initiated',
  INSURANCE_CONFIRMATION = 'Insurance Confirmation',
  CLAIM_RESOLVED = 'Claim Resolved',
}

export enum PlanValue {
  ALL = 'All',
  MYACCOUNT = 'My account',
  LOGIN = 'login',
}

export enum CurrentPlan {
  ALL = 'All',
  CURRENT = 'Current',
  PREVIOUS = 'Previous',
}

export enum ThreatStatus {
  CLAIM_INITIATED = 'Claim Initiated',
  INCIDENT_DETECTED = 'Incident Detected',
  INCIDENT_VERIFIYING = 'Incident Verified',
  INSURANCE_CONFIRMATION = 'Insurance Confirmation',
  USER_CONFIRMATION = 'User Confirmed',
  BLOCK_CREATION = 'Block Created',
  CLAIM_RESOLVED = 'Claim Resolved',
}

export enum ROUTE_ENUM {
  HOME_ROUTE = '/',
  LOGIN_ROUTE = '/login',
  SIGNUP_ROUTE = '/signup',
  FORGOT = '/forgot',
  SET_PASSWORD = '/set-password',
  TWOFA = '/two-fa',
}

export enum CLAIM_ACTION {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

export enum STATUS_MAPPING {
  ACTIVE = 'Active',
  SUCCESS = 'success',
  PENDING = 'Pending',
  WARNING = 'warning',
  INACTIVE = 'Inactive',
  ERROR = 'error',
  COMING_SOON = 'coming soon',
  INFO = 'info',
  EXPIRED = 'Expired',
  ACTIVATED = 'activated',
  INACTIVATED = 'deactivated',
  ACTIVATE = 'Activate',
  INACTIVATE = 'Deactivate',
}

export enum PaymentRequired {
  YES = 'yes',
  NO = 'no',
}

export enum PaymentType {
  DIRECT_PAYMENT = 'Direct Payment',
  STRIPE_PAYMENT = 'Stripe payment',
}

export enum ResendEmail {
  LABEL = 'Resend Email',
  TITLE = 'Resend Verification Email',
  CONTENT = 'Are you sure you want to resend verification email',
}

export enum ResendPaymentReminder {
  LABEL = 'Resend Email',
  TITLE = 'Resend Payment Due Reminder Email',
  CONTENT = 'Are you sure you want to resend payment due reminder email',
}
export enum CompanyStatus {
  ALL = 'All',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  BLOCKED = 'Blocked',
}

export enum PlanStatus {
  ALL = 'All',
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  OVERDUE = 'Payment Overdue',
  CANCELLED = 'Cancelled',
  INCOMPELE = 'Incomplete Application',
  SERVICE_ACTIVATION = 'Service Activation Pending',
}

export const AdminRoles = {
  SUB: 'Sub',
  SUPER: 'Super',
}

export const TrackingType = {
  MANUAL: 'Manual Tracking',
  AUTO: 'Auto Tracking',
}

export const defaultImage = {
  admin: '/images/img-placeholder.png',
  company: '/images/logo-placeholder.png',
  document: '/images/document-placeholder.png',
}

export const keyEnum = {
  ENTER: 'Enter',
}

export const MESSAGE_ENUM = {
  VERIFICATION_LINK: 'to login to your account please verify your account. we have sent a verification link to your registered email',
  ERROR_MESSAGE: 'DLT Server is facing some temporary issue, please try again later',
}

export const defaultResendEmail = {
  open: false,
  email: null,
}
export const defaultNotificationWithError = {
  open: false,
  error: null,
}
export enum ServicesEnum {
  EDR = 'Sentinel One',
  COVE_DATA = 'Cove Data Protection',
  N_SIGHT_PATCH = 'N-Sight-Patch Managment',
  N_SIGHT_AV = 'N-Sight AV (Bitdefender)',
  N_CENTERAL = 'N-Central',
}
export enum EvaluationStatus {
  EDR = 'EDR',
  COVE = 'Cove',
  NSIGHT = 'NSight',
}
export enum ServicesAssessmentsEnum {
  EDR = 'Sentinel One',
  COVE_DATA = 'Cove Data Protection',
  N_SIGHT_PATCH = 'N-Sight-Patch Management',
  Crowd_Strike = 'Crowd Strike',
  Bitdefender_Gravity = 'BitDefender Gravity Zone',
  Malware_Bytes = 'Malware Bytes',
  Windows_Defender = 'Windows Defender',
}
export enum ApiErrorCode {
  CODE = 'ERR_NETWORK',
}
export enum RiskScoreEmailEnum {
  LABEL = 'Send Email',
  TITLE = 'Risk Score Email',
  CONTENT = 'Are you sure you want to send risk score email to this user?',
}
export const defaultStateForRiskScoreEmailToUser: RiskScoreEmailToUserState = {
  open: false,
  error: null,
  userId: '',
  status: '',
}
