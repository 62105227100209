import { BrowserRouter, Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom'
import { globalStyles } from 'styles'
import { routes } from 'configs/constants'
import { lazy, Suspense, useState } from 'react'
import { Flex } from 'components/elements/Flex'
import { SidebarLayout } from 'components/sidebarLayout'
import useAuth from 'hooks/use-auth'
import Header from 'components/Header'
import { Box, Spinner } from 'components/elements'
import SubSidebarMenu from 'components/sidebarLayout/Sidebar/SubSidebarMenu'
import { styled } from 'styles/stitches.config'
import { AdminRoles } from 'utils/enum'
import Hamburder from 'components/icons/hamburger/hamburger'

export const SuspenseLoader = () => {
  return (
    <Box
      css={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Spinner />
    </Box>
  )
}

const Suspended = (Component: any) => (props: any) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  )

// auth
const Login = Suspended(lazy(() => import('features/auth/pages/Login')))
const Register = Suspended(lazy(() => import('features/auth/pages/Register')))
const VerfiyEmail = Suspended(lazy(() => import('features/auth/pages/VerifyEmail')))
const ForgotPassword = Suspended(lazy(() => import('features/auth/pages/ForgotPassword')))
const ResetPassword = Suspended(lazy(() => import('features/auth/pages/ResetPassword')))
// users
const Users = Suspended(lazy(() => import('features/users/pages/Users')))
// incidents
const Incidents = Suspended(lazy(() => import('features/incidents/pages/Incidents')))
// insurancePlans
const InsurancePlans = Suspended(lazy(() => import('features/insurancePlans/pages/InsurancePlans')))
const AddInsurancePlan = Suspended(lazy(() => import('features/insurancePlans/pages/AddInsurancePlan')))
const AddUser = Suspended(lazy(() => import('features/users/pages/AddUser')))
// setting
const Profile = Suspended(lazy(() => import('features/setting/pages/Profile')))
const Admins = Suspended(lazy(() => import('features/setting/pages/Admins')))
const AddAdmin = Suspended(lazy(() => import('features/setting/pages/AddAdmin')))
const CompanyDetails = Suspended(lazy(() => import('features/setting/pages/CompanyDetails')))
const CompanyServices = Suspended(lazy(() => import('features/setting/pages/CompanyServices')))
const SetPassword = Suspended(lazy(() => import('features/auth/pages/SetPassword')))
const AddPolicy = Suspended(lazy(() => import('features/insurancePlans/pages/AddPolicy')))

const ResendVerificationLink = Suspended(lazy(() => import('features/auth/pages/ResendVerificationLink')))

// TODO: move this to a layouts folder
const AuthLayout = () => {
  return (
    <>
      <Header />
      <Flex justify="center" css={QouteLayout}>
        <Flex align="center" css={{ minHeight: 'calc(100vh - 85px)', maxWidth: 550, width: '100%' }}>
          <Outlet />
        </Flex>
      </Flex>
    </>
  )
}

const SettingLayout = () => {
  const [toggleSidebar, setToggleSidebar] = useState<boolean>(false)

  const sidebarToggle = () => {
    setToggleSidebar?.(!toggleSidebar)
  }

  return (
    <>
      <Flex align="center" css={LayoutTitleWrapCSS}>
        <Box as="h3" css={LayoutTitleCss}>
          Settings
        </Box>
        <Hamburder sidebarToggle={sidebarToggle} toggleSidebar={toggleSidebar} hideMd={true} />
      </Flex>
      <LayoutWrapper>
        <SubSidebarMenu toggleSidebar={toggleSidebar} sidebarToggle={sidebarToggle} />
        <Outlet />
      </LayoutWrapper>
    </>
  )
}

const Protected = ({ children }: { children: JSX.Element }) => {
  const { user, authStatus, error } = useAuth()
  const { pathname } = useLocation()

  if (authStatus === 'loading') return <div>Loading...</div>

  if (authStatus === 'error') {
    // eslint-disable-next-line no-console
    console.log(error)
    return <div>Error loading</div>
  }

  if (!user) {
    return <Navigate to={routes.login} state={{ from: pathname }} />
  }
  return children
}

const App = () => {
  const { user } = useAuth()
  globalStyles()

  return (
    <BrowserRouter>
      <Routes>
        {/* TODO: logged in user should not be able to accss these routes, wrap in Public */}
        <Route path="/" element={<AuthLayout />}>
          <Route index element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="verify-email" element={<VerfiyEmail />} />
        </Route>
        <Route path="/auth" element={<AuthLayout />}>
          <Route path="set-password" element={<SetPassword />} />
          <Route path="resend-verification-link" element={<ResendVerificationLink />} />
        </Route>
        <Route
          path="/app"
          element={
            <Protected>
              <SidebarLayout />
            </Protected>
          }
        >
          <Route path="users" element={<Users />} />
          <Route path="incidents" element={<Incidents />} />
          <Route path="insurance-plans" element={<InsurancePlans />} />
          <Route path="insurance-plans/add-policy" element={<AddPolicy />} />
          <Route path="insurance-plans/add-plan" element={<AddInsurancePlan />} />
          <Route path="setting" element={<SettingLayout />}>
            <Route index element={<Profile />} />
            <Route path="profile" element={<Profile />} />
            {user?.role === AdminRoles.SUPER && (
              <>
                <Route path="admins" element={<Admins />} />
                <Route path="add-admin" element={<AddAdmin />} />
                <Route path="company-details" element={<CompanyDetails />} />
              </>
            )}
            <Route path="company-services" element={<CompanyServices />} />
          </Route>
          <Route path="users/add-user" element={<AddUser />} />
          <Route path="*" element={<Users />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export const QouteLayout = {
  'backgroundImage': "url('/images/bg-pattern.png')",
  'pt': '4.25rem',
  'backgroundPosition': 'center',

  '@md': {
    pt: 0,
  },
}

const LayoutTitleCss = {
  'p': '0.5rem 1.5rem',
  'm': 0,

  '@md': {
    px: '2.5rem',
    left: '$sidebarWidth',
  },
}

const LayoutTitleWrapCSS = {
  'position': 'fixed',
  'width': '100%',
  'pb': '1rem',
  'zIndex': 49,
  // backgroundColor: '$background',
  'backdropFilter': 'blur(3px)',
  'borderBottom': '1px solid $gray2',

  '@sm': {
    position: 'static',
  },
}

const LayoutWrapper = styled('div', {
  'position': 'relative',
  'mt': 73,

  '@sm': {
    mt: 0,
  },
})

export default App
